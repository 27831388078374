
import Vue from 'vue'
import Component from 'vue-class-component'

import ALL_PROGRAMS from '@/data/eligibility-calculator/all-programs'
import { CEC_PROGRAM } from '@/data/eligibility-calculator/programs/cec-eligibility'
import { FSW_PROGRAM } from '@/data/eligibility-calculator/programs/fsw-eligibility'
import { FST_PROGRAM } from '@/data/eligibility-calculator/programs/fst-eligibility'
import Program from '@/data/entity/programs/program.entity'
import EligibilityCriterionName from '@/data/entity/programs/eligibility-criterion-names'
import UiUtils from '../../util/ui-utils'

@Component({
  head: {
    title: UiUtils.translateTitle('label.programs'),
  },
})
export default class ProgramsScreen extends Vue {
  programs: Program[] = ALL_PROGRAMS
  cecProgram = CEC_PROGRAM
  fswProgram = FSW_PROGRAM
  fstProgram = FST_PROGRAM

  eligibilityCriteria: EligibilityCriterionName[] = [
    'language',
    'workExpType',
    'workExpAmount',
    'jobOffer',
    'education',
  ]

  getCriterionDescription(program: Program, criterionName: EligibilityCriterionName): string {
    const criterion = program.eligibilityCriteria.find(it => it.title === criterionName)
    return criterion ? criterion.description : this.$ts('label.notRequired')
  }
}
